/* Trong file CSS của bạn */
.high-value {
    background-color: #ff9999; /* Màu nền cho các giá trị cao */
  }
  
  .low-value {
    background-color: #99ff99; /* Màu nền cho các giá trị thấp */
  }

  /* StatisticsTable.css */
.statistics-table {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000; /* Ensure it's above other elements */
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.statistics-table.minimized {
  height: 40px; /* Adjust height for minimized view */
  overflow: hidden;
  padding: 0;
}

.minimize-button {
  display: block;
  width: 100%;
  text-align: center;
  border: none;
  background-color: #007bff;
  color: white;
  padding: 5px;
  cursor: pointer;
}

.minimize-button:focus {
  outline: none;
}
