html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.map-container {
  width: 100%;
}

.map-content {
  height: calc(100vh - 56px); /* Adjust based on your header height */
}

.sidebar {
  width: 280px; /* Increased width to accommodate longer Vietnamese text */
  transition: all 0.3s ease;
  overflow-y: auto;
}

.sidebar.collapsed {
  width: 50px;
}

.sidebar-content {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar.collapsed .sidebar-content {
  opacity: 0;
  pointer-events: none;
}

.map-wrapper {
  position: relative;
  flex-grow: 1;
}

.leaflet-container {
  height: 100% !important;
  width: 100% !important;
}

/* Ensure the row takes full height */
.map-content > .row {
  height: 100%;
  margin: 0;
}

/* Remove default padding from Bootstrap columns */
.map-content > .row > [class^="col-"] {
  padding: 0;
}

.toggle-sidebar-btn {
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Custom styles for search components */
.search-free-text,
.search-spatial-form {
  margin-bottom: 1rem;
}

.search-free-text input,
.search-spatial-form input {
  margin-bottom: 0.5rem;
}

/* You might want to adjust these styles for better text wrapping */
.sidebar-content button {
  white-space: normal;
  text-align: left;
  height: auto;
  min-height: 38px;
}
