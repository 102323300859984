/* FeatureInfo.css */
.feature-info {
    position: absolute;
    top: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    transform: translateY(0%);
    z-index: 1000;
  }
  